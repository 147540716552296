import { email, max, min, numeric, regex, required } from "@vee-validate/rules";
import { defineRule } from "vee-validate";

export default defineNuxtPlugin({
  name: "vee-validate",
  parallel: true,
  setup(nuxtApp) {
    // Skip loading on home page
    if (import.meta.client && nuxtApp.ssrContext?.url === ROUTES.home) return;

    defineRule("required", (value: string, [fieldName]: string[]) => {
      if (!required(value) || (value.constructor === Object && Object.keys(value).length === 0))
        return `${fieldName} is required`;
      return true;
    });

    defineRule("required-min-3", (value: string, [fieldName]: string[]) => {
      if (!required(value)) return `${fieldName} is required`;
      if (!min(value, { length: 3 })) return `${fieldName} must be 3 or more characters`;
      return true;
    });

    defineRule("deleteAccount", (value: string) => {
      if (!required(value)) return "Please type 'DELETE' to delete your account";
      if (value !== "DELETE") return "Please enter text exactly as displayed to delete your account";
      return true;
    });

    defineRule("url", (value: string, [fieldName]: string[]) => {
      if (!required(value)) return `${fieldName} is required`;
      if (
        !regex(value, {
          regex:
            "https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,}",
        })
      )
        return `${fieldName} must be a valid url`;
      return true;
    });

    defineRule("email", (value: string) => {
      if (!required(value)) return "The email field is required";
      if (!email(value)) return "A valid email address is required";
      return true;
    });

    defineRule("numeric-required", (value: string, [fieldName]: string[]) => {
      if (!required(value)) return `${fieldName} is required`;
      if (!numeric(value)) return `${fieldName} can only contain numbers`;
      return true;
    });
    defineRule("numeric", (value: string, [fieldName]: string[]) => {
      if (!numeric(value)) return `${fieldName} can only contain numbers`;
      return true;
    });

    defineRule("password", (value: string) => {
      if (!required(value)) return "The password field is required";
      if (!min(value, { length: 6 }) && max(value, { length: 20 }))
        return "Password must be between 6 and 20 characters";
      return true;
    });

    defineRule("confirmPassword", (value: string, [other]: string[]) => {
      if (!required(value)) return "The confirm password field is required";
      if (value !== other) return "Passwords do not match";
      return true;
    });
  },
});
