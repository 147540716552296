import { vTooltip } from "floating-vue";

export default defineNuxtPlugin({
  name: "v-tooltip",
  parallel: true,
  setup(nuxtApp) {
    // Skip loading on home page
    if (import.meta.client && nuxtApp.ssrContext?.url === ROUTES.home) return;

    nuxtApp.vueApp.directive("tooltip", vTooltip);
  },
});
