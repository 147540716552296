import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import supabase_client_2ruDq4uEtU from "/vercel/path0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ghbUAjaD3n from "/vercel/path0/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_UYiXMU8ZyN from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import primevue_plugin_egKpok8Auk from "/vercel/path0/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/vercel/path0/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_tMGwffvjBc from "/vercel/path0/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_WLsn00x1qh from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_BqLkNUef68 from "/vercel/path0/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import auth_redirect_bWtzICY6fh from "/vercel/path0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import plugin_AUP22rrBAc from "/vercel/path0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import capture_error_client_bnX3WWpp31 from "/vercel/path0/plugins/capture-error.client.ts";
import casl_QTBkbB6FCs from "/vercel/path0/plugins/casl.ts";
import cookie_consent_client_xnDplH3AlY from "/vercel/path0/plugins/cookie-consent.client.ts";
import dompurify_html_VcfsMfUvBB from "/vercel/path0/plugins/dompurify-html.ts";
import gmap_loader_SeBSlfL26v from "/vercel/path0/plugins/gmap-loader.ts";
import google_recaptcha_rYK9Kziv68 from "/vercel/path0/plugins/google-recaptcha.ts";
import posthog_ulvgx15Dgc from "/vercel/path0/plugins/posthog.ts";
import reset_pinia_UDAU7JhdLZ from "/vercel/path0/plugins/reset-pinia.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import v_tooltip_J4SXZrA1Eq from "/vercel/path0/plugins/v-tooltip.ts";
import vee_validate_KcKlKmvCrJ from "/vercel/path0/plugins/vee-validate.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  supabase_client_2ruDq4uEtU,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_ghbUAjaD3n,
  plugin_client_UYiXMU8ZyN,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  plugin_tMGwffvjBc,
  plugin_WLsn00x1qh,
  plugin_client_OVoKJro5pc,
  plugin_BqLkNUef68,
  auth_redirect_bWtzICY6fh,
  plugin_AUP22rrBAc,
  capture_error_client_bnX3WWpp31,
  casl_QTBkbB6FCs,
  cookie_consent_client_xnDplH3AlY,
  dompurify_html_VcfsMfUvBB,
  gmap_loader_SeBSlfL26v,
  google_recaptcha_rYK9Kziv68,
  posthog_ulvgx15Dgc,
  reset_pinia_UDAU7JhdLZ,
  sentry_client_shVUlIjFLk,
  v_tooltip_J4SXZrA1Eq,
  vee_validate_KcKlKmvCrJ
]