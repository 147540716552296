import { defineNuxtPlugin } from "#app";
import { abilitiesPlugin } from '@casl/vue';

import { createMongoAbility } from '@casl/ability';

export const initialAbilities = createMongoAbility([
  {
    action: 'all',
    subject: 'all',
    inverted: true
  }
])


export default defineNuxtPlugin({
  name: "casl",
  parallel: true,
  async setup(nuxtApp) {
    // Skip loading on home page
    if (import.meta.client && nuxtApp.ssrContext?.url === ROUTES.home) return;

    nuxtApp.vueApp.use(abilitiesPlugin, initialAbilities);
  },
});