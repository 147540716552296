export default defineNuxtPlugin({
  name: "posthog",
  parallel: true,
  setup() {
    if (import.meta.client) {
      const scheduleInit = () => {
        if ("requestIdleCallback" in window) {
          window.requestIdleCallback(() => usePostHog(), { timeout: 5000 });
        } else {
          setTimeout(() => usePostHog(), 3000);
        }
      };

      if (document.readyState === "complete") {
        scheduleInit();
      } else {
        window.addEventListener("load", scheduleInit);
      }
    }
  },
});
