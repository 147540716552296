export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig();
  const siteKey = config.public.recaptchaSiteKey;
  let recaptchaLoaded = false;
  let loadingPromise: Promise<void> | null = null;

  if (import.meta.client) {
    const style = document.createElement('style');
    style.innerHTML = '.grecaptcha-badge { visibility: hidden !important; }';
    document.head.appendChild(style);
  }

  if (import.meta.client) {
    const hints = [
      { rel: 'dns-prefetch', href: 'https://www.google.com' },
      { rel: 'dns-prefetch', href: 'https://www.gstatic.com' },
      { rel: 'preconnect', href: 'https://www.google.com', crossorigin: 'anonymous' },
      { rel: 'preconnect', href: 'https://www.gstatic.com', crossorigin: 'anonymous' }
    ];

    hints.forEach(({ rel, href, crossorigin }) => {
      const link = document.createElement('link');
      link.rel = rel;
      link.href = href;
      if (crossorigin) link.crossOrigin = crossorigin;
      document.head.appendChild(link);
    });
  }

  const loadRecaptcha = () => {
    if (loadingPromise) return loadingPromise;

    loadingPromise = new Promise<void>((resolve, reject) => {
      if (import.meta.server) resolve();
      if (window.grecaptcha) {
        recaptchaLoaded = true;
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.async = true;
      
      script.onload = () => {
        window.grecaptcha.ready(() => {
          recaptchaLoaded = true;
          resolve();
        });
      };

      script.onerror = (error) => {
        loadingPromise = null;
        console.error('Failed to load reCAPTCHA script:', error);
        reject(new Error('Failed to load reCAPTCHA'));
      };

      document.head.appendChild(script);
    });

    return loadingPromise;
  };

  if (import.meta.client) {
    function onUserInteraction() {
      if (!recaptchaLoaded) {
        recaptchaLoaded = true;
        loadRecaptcha().catch(error => {
          console.error('Failed to initialize reCAPTCHA:', error);
        });
        
        ['mousedown', 'keydown', 'touchstart', 'scroll'].forEach(event => 
          document.removeEventListener(event, onUserInteraction)
        );
      }
    }

    // Add event listeners with passive and capture options
    ['mousedown', 'keydown', 'touchstart', 'scroll'].forEach(event => 
      document.addEventListener(event, onUserInteraction, { passive: true, capture: true })
    );

    // Fallback: Load after 5s if no interaction
    setTimeout(() => {
      if (!recaptchaLoaded) {
        loadRecaptcha().catch(error => {
          console.error('Failed to initialize reCAPTCHA:', error);
        });
      }
    }, 5000);
  }

  const executeRecaptcha = async (action: string): Promise<string> => {
    if (!recaptchaLoaded) {
      await loadRecaptcha();
    }

    try {
      const token = await window.grecaptcha.execute(siteKey, { action });
      return token;
    } catch (error) {
      console.error('ReCAPTCHA execution failed:', error);
      throw error;
    }
  };

  return {
    provide: {
      recaptcha: {
        execute: executeRecaptcha
      }
    }
  };
});
