import { Loader, type LoaderOptions } from "@googlemaps/js-api-loader";

export default defineNuxtPlugin({
  name: "gmap-loader",
  parallel: true,
  setup: (nuxtApp) => {
    // Skip loading on home page
    if (import.meta.client && nuxtApp.ssrContext?.url === ROUTES.home) {
      return;
    }

    const config = useRuntimeConfig();

    const loaderOptions: LoaderOptions = {
      apiKey: config.public.googleMapsApiKey,
      id: "__googleMapsScriptId",
      version: "weekly",
      libraries: ["geometry"],
    };

    const loader = new Loader(loaderOptions);

    return {
      provide: {
        gMapLoader: () => loader,
      },
    };
  },
});
