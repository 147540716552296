import posthog from 'posthog-js'

export function usePostHog() {
  const config = useRuntimeConfig();

  posthog.init(config.public.posthog.apiKey, {
    api_host: config.public.posthog.apiHost,
  });

  return {
    posthog,  
  };
}