export default defineNuxtPlugin({
  name: "sentry-client",

  async setup(nuxtApp) {
    const router = useRouter();
    const config = useRuntimeConfig();
    const {
      public: { sentry },
    } = useRuntimeConfig();

    if (!sentry.dsn || config.public.env === "local") {
      return {
        provide: {
          sentryCaptureException: async () => {},
        },
      };
    }

    let sentryInstance: typeof import("@sentry/vue") | null = null;
    let initializationPromise: Promise<typeof import("@sentry/vue")> | null = null;

    // Define routes where Sentry should be disabled
    const shouldDisableSentry = (route: string) => {
      // Add routes where Sentry should be disabled
      const sentryDisabledRoutes = [
        ROUTES.home,
        ROUTES.blog,
        ROUTES.maintenance,
        ROUTES.employers,
        ROUTES.candidates,
        ROUTES.howToDeleteAccount,
        ROUTES.candidatesFeatures,
        ROUTES.employersPricing,
        ROUTES.employersFeatures,
      ];

      return sentryDisabledRoutes.some((pattern) => {
        // Exact match or starts with pattern
        return route === pattern || route.startsWith(`${pattern}/`);
      });
    };

    // Defer Sentry initialization
    const initSentry = async () => {
      if (shouldDisableSentry(router.currentRoute.value.path)) {
        return null;
      }

      if (sentryInstance) return sentryInstance;
      if (initializationPromise) return initializationPromise;

      initializationPromise = (async () => {
        const Sentry = await import("@sentry/vue");

        Sentry.init({
          app: nuxtApp.vueApp,
          dsn: sentry.dsn,
          environment: config.public.env,
          integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
              maskAllText: false,
              blockAllMedia: false,
            }),
            Sentry.captureConsoleIntegration({
              levels: ["error"],
            }),
          ],
          tracesSampleRate: parseFloat(sentry.tracesSampleRate),
          tracePropagationTargets: ["localhost", config.public.appUrl],
          replaysSessionSampleRate: parseFloat(sentry.replaysSessionSampleRate),
          replaysOnErrorSampleRate: parseFloat(sentry.replaysOnErrorSampleRate),
        });

        sentryInstance = Sentry;
        return Sentry;
      })();

      return initializationPromise;
    };

    // Initialize when browser is idle and on appropriate routes
    if (import.meta.client) {
      const scheduleInit = () => {
        if (shouldDisableSentry(router.currentRoute.value.path)) return;

        if ("requestIdleCallback" in window) {
          window.requestIdleCallback(() => initSentry(), { timeout: 5000 });
        } else {
          setTimeout(() => initSentry(), 3000);
        }
      };

      // Schedule initialization after initial page load
      if (document.readyState === "complete") {
        scheduleInit();
      } else {
        window.addEventListener("load", scheduleInit);
      }

      // Watch for route changes
      router.afterEach((to) => {
        if (!shouldDisableSentry(to.path) && !sentryInstance) {
          scheduleInit();
        }
      });
    }

    return {
      provide: {
        sentryCaptureException: async (error: any) => {
          if (!import.meta.client) return;
          if (shouldDisableSentry(router.currentRoute.value.path)) return;

          const SentryModule = sentryInstance || (await initSentry());
          if (SentryModule) SentryModule.captureException(error);
        },
      },
    };
  },
});
