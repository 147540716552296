import type { PiniaPluginContext } from "pinia";
import type { Pinia } from "pinia";

function ResetPiniaPlugin({ store, pinia }: PiniaPluginContext) {
  store.$reset = () => {
    store.$dispose();
    delete pinia.state.value[store.$id];
  };
}

export default defineNuxtPlugin({
  name: "reset-pinia",
  parallel: true,
  setup(nuxtApp) {
    // Skip loading on home page
    if (import.meta.client && nuxtApp.ssrContext?.url === ROUTES.home) return;

    const pinia = nuxtApp.$pinia as Pinia;
    pinia.use(ResetPiniaPlugin);
  },
});
